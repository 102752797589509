import $ from 'jquery';

import objectFitImages from 'object-fit-images';
import navigation from './components/layout/navigation';
import slick from './components/layout/slick';
import responsiveVideos from './components/layout/responsiveVideos';
import validation from './components/forms/validation';
import RecaptchaForm from './components/forms/RecaptchaForm';
import gaTracking from './components/tracking/gatracking';

import Contact from './pages/Contact';
import loadMore from './components/layout/loadMore';
import select from './components/layout/select';

require('./utils/nativeConsole');

// Set js class
const htmlClassList = document.documentElement.classList;
htmlClassList.add('js');
htmlClassList.remove('no-js');

// Layout setup
navigation();
responsiveVideos();
loadMore();
slick();
select();

// Forms
validation();

// Tracking
gaTracking();

const newsletterForm = new RecaptchaForm('#form-newsletter');
window.submitRecaptchaFormNewsletter = () => {
  newsletterForm.submitCallback();
};

let expanded = false;

const showCheckboxes = () => {
  const checkboxes = document.getElementById('checkboxes');
  if (checkboxes) {
    if (!expanded) {
      checkboxes.style.display = 'block';
      expanded = true;
    } else {
      checkboxes.style.display = 'none';
      expanded = false;
    }
  }
};

const ofi = () => {
  const someImages = $(
    '.ghost__image img, .news__card__image img, .slick-slider img, .staff-member img, .stretched__image--horizontal img',
  );
  objectFitImages(someImages);
};

showCheckboxes();
ofi();

// Enable this if you want to test ga calls in development
// gaDevelopment();

// Page specific classes
const pages = {
  Contact,
};

const currentPage = document.documentElement.getAttribute('data-page');
if (currentPage) {
  const pageClassName = currentPage.charAt(0).toUpperCase() + currentPage.slice(1);

  if (pageClassName !== '' && typeof pages[pageClassName] === 'function') {
    // Exceptional use of new
    // eslint-disable-next-line no-new
    new pages[pageClassName]();
  }
}
