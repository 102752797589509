import $ from 'jquery';

export default function () {
  const loadMore = (e) => {
    e.preventDefault();

    $.ajax($(e.currentTarget).attr('href')).done((data) => {
      $('.js-load-more-container').append(data.content);

      if (!data.next_url) {
        $(e.currentTarget).hide();
      } else {
        $(e.currentTarget).attr('href', data.next_url);
      }
    });
  };

  $('.js-load-more').on('click', loadMore);
}
