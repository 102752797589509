import $ from 'jquery';

require('select2/dist/js/select2');
require('select2/dist/js/select2.full');

export default function () {
  $('#js--select--country').select2({
    closeOnSelect: false,
  });
  $('#js--select--theme').select2({
    closeOnSelect: false,
  });
  $('#js--select--status').select2({
    closeOnSelect: false,
  });

  const countryPlaceholder = $('#js--select--country').attr('data-placeholder');
  const themePlaceholder = $('#js--select--theme').attr('data-placeholder');
  const statusPlaceholder = $('#js--select--status').attr('data-placeholder');

  $('select').on('change', () => {
    setTimeout(() => {
      $(
        '#js--select--country + .select2 .select2-selection__rendered .select2-search__field',
      ).attr('placeholder', countryPlaceholder);
      $(
        '#js--select--theme + .select2 .select2-selection__rendered .select2-search__field',
      ).attr('placeholder', themePlaceholder);
      $(
        '#js--select--status + .select2 .select2-selection__rendered .select2-search__field',
      ).attr('placeholder', statusPlaceholder);
    }, 10);
    $('.select2 .select2-selection__rendered .select2-selection__choice').each(
      (key, val) => {
        $('.country-filters .select2-selection__rendered').append(val);
      },
    );
  });

  setTimeout(() => {
    $(
      '#js--select--country + .select2 .select2-selection__rendered .select2-search__field',
    ).attr('placeholder', countryPlaceholder);
    $(
      '#js--select--theme + .select2 .select2-selection__rendered .select2-search__field',
    ).attr('placeholder', themePlaceholder);
    $(
      '#js--select--status + .select2 .select2-selection__rendered .select2-search__field',
    ).attr('placeholder', statusPlaceholder);
  }, 10);
  $('.select2 .select2-selection__rendered .select2-selection__choice').each(
    (key, val) => {
      $('.country-filters .select2-selection__rendered').append(val);
    },
  );

  $('.select2-selection__choice').addClass('applied-filter');
}
