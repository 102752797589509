/* eslint-disable operator-linebreak */
/* eslint-disable quotes */
/* eslint-disable func-names */
import $ from "jquery";
import { matchMobile } from "../../constants/defaults";

require("modaal/dist/js/modaal.min");

export default function () {
  const $nav = $(".main-nav__wrap");

  $(".main-nav__trigger").click((e) => {
    e.preventDefault();
    $(e.currentTarget).next(".main-nav__wrap").slideToggle("fast");
  });

  matchMobile.addListener((mediaQuery) => {
    if (mediaQuery.matches) {
      $nav.css("display", "block");
    } else {
      $nav.css("display", "none");
    }
  });

  $(".hover-menu > a").click(function (e) {
    e.preventDefault();
    $(this).parent().find(".submenu").slideToggle();
    $(this).parent().toggleClass("opened");
  });

  $(document).click((event) => {
    const target = $(event.target);
    if (
      !target.closest(".hover-menu > a").length &&
      $("html").hasClass("open")
    ) {
      $("html").removeClass("open");
      $(".hover-menu").removeClass("open");
    }
  });

  $(".icon--search").click(() => {
    $(".extra-nav").toggleClass("extra-nav__open");
    $(".search").toggleClass("active-search");
  });

  $(".main-nav__trigger").modaal({
    after_open() {
      $("body").css("overflow", "hidden");
      $("body").css("height", "100vh");
      $("body").css("max-height", `calc(${window.innerHeight * 0.01}px * 100)`);
    },
    after_close() {
      $("body").css("overflow", "visible");
      $("body").css("max-height", "auto");
      $("body").css("height", "auto");
    },
  });
  $(".main-nav__trigger").click(() => {
    setTimeout(() => {
      $(".modaal-wrapper").find(".mobile__nav").addClass("in");
    }, 10);
  });
  $(".modaal-close").click(() => {
    $(".modaal-wrapper").find(".mobile__nav").removeClass("in");
  });
}
