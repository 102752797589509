/* eslint-disable no-eval */
/* eslint-disable func-names */
/* eslint-disable no-unused-vars */
import $ from 'jquery';

require('slick-carousel/slick/slick.min');

export default function () {
  $('.slick-slider').slick({
    arrows: false,
    dots: true,
  });

  $('#slider--header').slick({
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    fade: true,
    cssEase: 'ease',
    autoplay: true,
    autoplaySpeed: 4000,
  });

  const sliderProjects = {
    arrows: true,
    slidesToShow: 3,
    infinite: false,
    prevArrow: $('.prev_arrow'),
    nextArrow: $('.next_arrow'),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const sliderHeader = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    fade: true,
    cssEase: 'ease',
    autoplay: true,
    autoplaySpeed: 4000,
  };

  $('.slider').each(function (key, val) {
    const slickIndividual = $(this);
    const options = eval(slickIndividual.attr('id'));
    slickIndividual
      .on('init', function (slick) {
        $(this).parent().removeClass('hidden');
      })
      .slick(options);
  });
}
